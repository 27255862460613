import * as React from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { actions } from "./slice";

export function AuthPage() {
  const searchParams = new URL(window.location.href).searchParams;
  const { macAddress } = useParams();
  const dispatch = useDispatch();
  const type = searchParams.get("type");
  const locale = searchParams.get("locale");
  const navigate = useNavigate();
  React.useEffect(() => {
    if (type === "LOGIN") {
      dispatch(actions.setType(type))
      navigate(`/${macAddress}/auth`);
    }
  }, [type, locale]);
  return <Outlet />;
}
